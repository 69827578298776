
import {useState} from "react";
import ss0 from "../../assets/dayzTill.png";
import ss1 from "../../assets/dayztillSettings.png";
import ss2 from "../../assets/bday.png";
import logo from "../../assets/dayzTillLogo.jpg"
export function DayzTillInfo() {
    const [imgs, setImgs] = useState([ss0, ss2, ss1]);
    const [index, setIdx] = useState(0);
    const [img, setImg] = useState(ss0);


    return(
        <div className='bg-black pb-20 ' >

            <div className='flex justify-center pt-10'>
                <h1 className='text-6xl font-bold text-white'> Dayz Till </h1>
            </div>
            <div className='flex justify-center pb-10'>
                <h5 className='text-sm font-bold text-white'>The simplest countdown ever! No subscription required!</h5>
            </div>

            <div className='flex justify-center'>
                <img src={logo} height={500} width={500} alt=""  style={{


                }}/>
            </div>


            <div className='flex justify-center'>
                <p className='text-white pb-4'>Tap / click Image to cycle</p>
            </div>




            <div className='flex justify-center'>


                <img src={img} alt="" width={300} className='rounded-3xl shadow shadow-gray-500' onClick={ (e) => {
                    console
                        .log("index = " + index)
                    setImg(imgs[index])
                    index === imgs.length - 1 ? setIdx(0) : setIdx(index + 1)
                } } />


            </div>

            <div className='flex justify-center'>
                <a href="https://apple.co/42JACTO" className='p-5'>
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                        alt="Download on the App Store"/>

                </a>
            </div>





        </div>
    )
}