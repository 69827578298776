import logo from "../../assets/BetaLogoNoBG.png"
import ss0 from "../../assets/dartsHome.png"
import ss1 from "../../assets/Cricket.png"
import ss2 from "../../assets/x01.png"
import ss3 from "../../assets/settings.png"
import {useEffect, useState} from "react";
export function DartopusInfo() {
    const [pos, setPos] = useState({ x: 0, y: 0, scale: 1 });
    const [imgs, setImgs] = useState([ss0, ss1, ss2, ss3]);
    const [index, setIdx] = useState(0);
    const [img, setImg] = useState(ss0);

    const onScroll = (e) => {
        const delta = e.deltaY * +0.01;
        const newScale = pos.scale + delta;

        const ratio = 1 - newScale / pos.scale;

        console.log(pos.y)
        setPos({
            scale: newScale,
            x: pos.x,
            y:  pos.y + (e.clientY - pos.y) * ratio,
        });
        console.log("Scale " + pos.scale)
    };

    useEffect(() => {
        console.log(pos.y)
    }, []);

    return(
        <div className='bg-black pb-20 ' onScrollCapture={onScroll} >

            <div className='flex justify-center pt-10'>
                <h1 className='text-6xl font-bold text-white'> Darts Keeper </h1>
            </div>
            <div className='flex justify-center pb-10'>
                <h5 className='text-sm font-bold text-white'>Over 1K unique downloads!</h5>
            </div>

            <div className='flex justify-center'>
                <img src={logo} height={500} width={500} alt=""  style={{

                    transform: `scale(${pos.scale}) translate(${pos.x}px, ${pos.y}px)`,
                }}/>
            </div>


            <div className='flex justify-center'>
                <p className='text-white pb-4'>Tap / click Image to cycle</p>
            </div>




            <div className='flex justify-center'>


                <img src={img} alt="" width={300} className='rounded-3xl shadow shadow-gray-500' onClick={ (e) => {
                    console
                        .log("index = " + index)
                    setImg(imgs[index])
                    index === imgs.length - 1 ? setIdx(0) : setIdx(index + 1)
                } } />

            </div>

            <div className='flex justify-center'>
                <a href="https://apps.apple.com/us/app/darts-keeper/id1570511877?itsct=apps_box_badge&amp;itscg=30200"
                   className='p-5'>
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                        alt="Download on the App Store"/>

                </a>
            </div>





        </div>
    )

}
