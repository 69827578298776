import {SideBar} from "../../components/SideBar";
import {ProjectCard} from "../../components/ProjectCard";
import '../../styles/project-card.css'
import meMoji from "../../assets/coryMemoji.png"

export function StarterProjects() {
    return (
        <div className='backGroundLTC rounded-b-xl shadow-inner shadow-black'>

            <div className='grid lg:grid-rows-3cl lg:grid-cols-2ltc  gap-6 p-6'>

                <SideBar />

                <div className='lg:col-start-2 lg:col-end-3 '>
                        {/*<h1 className='text-center p-5 text-6xl text-white font-bold'> Let's Get Started!! </h1>*/}
                    <h1 className='text-center p-5 text-6xl sm:text-7xl font-bold text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> Let's Get Coding!! </h1>

                </div>

                <div className='rounded-2xl lg:row-start-2 lg:row-end-4'>
                    <div className='grid lg:grid-cols-2 lg:grid-rows-2 gap-8'>


                        <div className='p-3 std-shadow rounded-2xl shadow-inner shadow-gray-400'>
                            <p className='py-3 text-white font-black text-3xl text-center bg-gradient-to-r from-violet-500 to-blue-400 bg-clip-text text-transparent'>
                                Here are some simple starter projects to help you practice your language of choice.
                            </p>

                            <p className='text-white text-xl py-3 font-black'>
                                If you are brand new to coding, then I suggest you start with the proverbial Hello World.
                                Once you have completed that, you can move on to more hands on practice/starter projects.
                            </p>
                            <p className='text-white text-xl py-3 font-black'>
                                All of these projects are commonly implemented projects for learning
                                and exploring a language/framework.
                            </p>

                            <p className='text-pink-400 text-xl py-3 font-black'>
                                - There should be plenty of resources online for each project. <br/>
                                - Each project has a github repo attached. <br/>
                                - Some of these are my repos, some are just good implementations.
                            </p>
                            <p className='text-white text-xl pt-3  font-black'>
                                In the <a href="/ltc/tutorials"> Tutorials! </a> section, I will be creating video
                                tutorials related to these projects.
                            </p>
                            <p className='text-white text-xl mt-10 text-center font-black'>
                                Check out the <a href="/ltc/resources"> Helpful Resources </a>
                                section as well for links to 3rd party resources.
                            </p>


                        </div>


                        <div className='std-shadow bg-gray-700 rounded-2xl'>

                            <div className=''>
                                <div className='card-left-bg flex justify-center p-4 rounded-2xl w-full flex-col items-center'>
                                    <h1 className='text-white font-bold sm:text-6xl text-5xl'>Hello, World</h1>
                                    <div className='py-1 mt-3 px-2 bg-gray-900 opacity-75 rounded-full '>
                                        <a href="https://github.com/corith/Hello-World" target='_blank' rel="noreferrer">GitHub Repo</a>
                                    </div>
                                </div>
                            </div>



                            <div className='flex justify-center'>
                                <img src={meMoji} alt="" width='250'/>
                            </div>

                            <div>
                                <p className='p-2 text-white font-bold text-xl text-center'>
                                    This is the notorious "Hello, world." project. All you need to do is make "Hello, World." be printed to the console in your language of choice.

                                </p>
                                <p className='p-2 text-white font-bold text-xl text-center'>
                                    Although simple, this exercise will get you familiar with what is required to actually get your code to compile, execute, and produce desired output
                                    in your language of choice.
                                </p>
                                <p className='p-2 text-white font-bold text-xl text-center'>
                                    Once completed, you should have a solid starting point for experimenting, simply start adding more code and print statements!
                                </p>
                            </div>


                            {/*<ProjectCard repoUrl='' projName='Hello World' lang='Java/Python/C' projDes='This is your typical Hello world project. All you need to do is make "Hello World" be printed to the console in your language of choice.' />*/}
                        </div>
                        <div className='flex justify-center'>
                            <ProjectCard repoUrl='' projName='To Do List' lang='Java' projDes='This is a simple to do list that will keep track tasks and return JSON payloads'/>
                        </div>
                        <div className='flex justify-center'>
                            <ProjectCard repoUrl='' projName='Builda-pi' lang='Java' projDes='For this project you should build an API that keeps track of users, allows them to login, and do a couple different operations of your choice.'/>
                        </div>
                        <div className='flex justify-center'>
                            <ProjectCard repoUrl='' projName='Card Game' lang='Python' projDes='This project will involve recreating one of your favorite card games on the command line using Python.'/>
                        </div>
                        <div className='flex justify-center'>
                            <ProjectCard repoUrl='' projName='Web Site' lang='Javascript/HTML/CSS' projDes='This project is for my web development people. You will be using vanilla javascript, html and css to build a frontend website.'/>
                        </div>

                    </div>
                </div>

             </div>
        </div>
    )
}
