import React from "react";
import { BrowserRouter as Router, Route , Switch } from "react-router-dom";
import { NavBar} from "./components/NavBar";

import { MyStudio } from './views/MyStudio';
import { Subscribe } from "./views/Subscribe";
import { Footer } from './components/Footer';
import {LearnToCode} from "./views/learnToCode/LearnToCode";
import "./fonts/Chalkduster.ttf"
// import {Stories} from "./views/Stories";
import {WishIknew} from "./views/learnToCode/WishIKnew";
import {ChooseLanguage} from "./views/learnToCode/ChooseLanguage";
import {StarterProjects} from "./views/learnToCode/StarterProjects";
import {LinuxBaby} from "./views/learnToCode/LinuxBaby";
import {HelpfulResources} from "./views/learnToCode/HelfpulResources";
import {Tutorials} from "./views/learnToCode/Tutorials";
import {PrivacyPolicy} from "./views/PrivacyPolicy";
import {WatchaThink} from "./views/WatchaThink";
import {HomePage2} from "./views/HomePage"
import {ActivityTiles} from "./views/showcases/ActivityTiles";
import {ARPTInfo} from "./views/showcases/ARPTInfo";
import {DartopusInfo} from "./views/showcases/DartopusInfo";
import {DayzTillInfo} from "./views/showcases/DayzTillInfo";
import {BaculatorInfo} from "./views/showcases/BaculatorInfo";
import {TapeEaterInfo} from "./views/showcases/TapeEaterInfo";

export const App = () => {
    return (
        <Router>

            <NavBar />

            <Switch>
                <Route path='/' component={HomePage2} exact />
                <Route path='/studio' component={MyStudio} />
                <Route path='/articles' component={WatchaThink} />
                <Route path='/ltc' component={LearnToCode} exact />
                <Route path='/subscribe' component={Subscribe} />

                <Route path='/ltc/first' component={ChooseLanguage}  />
                <Route path='/ltc/start' component={StarterProjects} />
                <Route path='/ltc/wik' component={WishIknew} />
                <Route path='/ltc/resources' component={HelpfulResources} />
                <Route path='/ltc/tutorials' component={Tutorials} />
                <Route path='/ltc/linux' component={LinuxBaby} />
                <Route path='/dev' component={ActivityTiles}/>
                <Route path='/activityTiles' component={ActivityTiles}/>
                <Route path='/arpt' component={ARPTInfo}/>
                <Route path='/baculator' component={BaculatorInfo}/>
                <Route path='/tape-eater' component={TapeEaterInfo}/>
                <Route path='/darts' component={DartopusInfo}/>
                <Route path='/dayztill' component={DayzTillInfo}/>
                <Route path='/privacy' component={PrivacyPolicy} />
            </Switch>
            <div className='p-2'>
                <Footer />
            </div>
        </Router>
    )

}
