
import userDetails from "../../assets/UserDetailsAlpha.png"
import userDetails1 from "../../assets/arptDetails1.png"
import userDetails2 from "../../assets/arptDetails2.png"
export function ARPTInfo() {
    return(
        <div className="bg-gradient-to-br from-gray-700 via-black to-red-600 rounded-b-2xl shadow-inner shadow-black">
            <div className="pb-20">

                <div className="flex flex-col items-center ">
                    <h1 className="text-red-500 text-7xl pt-10 font-bold "> A  R  P  T </h1>
                    <h4 className="text-gray-400 text-2xl pt-2"> Apex Ranked Pocket Tracker</h4>
                </div>

                <div className="">
                    <div className="text-white p-5 text-lg">
                        <h1 className="text-4xl font-bold pb-5">About ARPT</h1>
                        <p>
                            ARPT is an app that can be used to track RP (Ranked Points) while playing the video game Apex Legends. Players get RP when they play Apex's "Ranked" multiplyer mode.
                            You earn RP for staying alive, high placement (1st, 2nd), and killing opponents. After Rookie, every player has to wager some RP in order to play, with higher ranks
                            having to wager more and more.
                        </p>

                        <p className="pt-5">
                            ARPT Allows players to easily keep track of how much RP they gained or lost, as well as some other stats like legend used, kills, assists, damage, etc. The only required
                            data entry is RP earned / lost. Users can then look back across time and see how their performance is trending and which legend is yielding them the most RP.
                        </p>
                    </div>

                    <div className='flex justify-end'>

                        <div className=' p-5'>
                            <h1 className="text-red-500 text-4xl flex justify-end font-bold pb-5">What killed ARPT?</h1>
                            <p className="text-white text-lg">
                                Unfortunately, I started developing this app and "finished" it exactly at the time that Apex Legends switch from RP to LP (Ladder points). Not onyl did they change the ranked points name,
                                but they changed how much LP you were awarded for surviving, killing, and placement. This ended up being a bit of a  disaster for the game. It resulting in essentially the breaking of the
                                entire ranked system. We saw a staggering amount of players reach rank of Master like never before. They currently are still trying to figure out the best way to change the ranked system so
                                that it is fun, fair, and competitive at all ranks. <br/><br/>
                                As a side effect, development on this app has stopped, at least for the time being.
                            </p>

                            <p className="text-red-400 pt-5">
                                **Although currently nothing has been modified to account for the changes to the Ranked System, the app still works and is useful at a high level for tracking "Points" (L/R)P and associating them
                                to a specific legend. So for that reason I will be keeping a live beta build on test flight in case people want to try and track their LP. Click download to get that live beta.
                                <br/><br/>
                                **As the Ranked System matures into a more stable  state, I would assume that development on this app will kick back up.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="flex gap-10 flex-col md:flex-row justify-center items-center ">
                    <img src={userDetails1} width={300}  alt="" className="px-10"/>
                    <img src={userDetails}  width={400}  alt="" className="px-10"/>
                    <img src={userDetails2} width={300}   alt="" className="px-10"/>
                </div>



                <div className="flex justify-center mt-10">
                    <a href="https://testflight.apple.com/join/mhzx2R2S" className="text-red-600 text-2xl bg-gray-800 p-5 rounded-2xl shadow-xl shadow-black">Download</a>
                </div>


            </div>
        </div>
    )
}