import "../../styles/activity-tiles.css"
import sixWidgets from "../../assets/two_widgets.png"
import addActivity from "../../assets/AddNewActivity.png"
import mmaTraining from "../../assets/Workouts_FSAV.png"
import homeView from "../../assets/homeView.png"
import graphKey from "../../assets/Sleep_FSAV.png"
import purple1 from "../../assets/Alcohol_FSAV.png"
// import purple1 from "../assets/Workouts_FYAV.png"

export function ActivityTiles() {

    return(

        <div className='std-shadow bg-gradient-to-br from-gray-600 via-black to-gray-600 to-black shadow-inner shadow-black'>
            <div className='flex justify-center p-10'>
                <h1 className='text-white text-7xl chalk text-center'> Activity Tiles </h1>
            </div>



            <div className="flex justify-center py-5">
                <a href="https://apps.apple.com/us/app/activity-tiles/id1670003422?platform=iphone"
                   className='p-5'>
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                        alt="Download on the App Store"/>

                </a>


                {/*<h1 className='text-lg font-bold text-center p-5'>*/}
                    {/*<a href="https://testflight.apple.com/join/hNbNRHO9" className='rounded-xl p-3 std-shadow bg-gradient-to-tr from-black via-purple-900 to-black text-puprle-400'>Beta Link 🔗</a>*/}
                {/*</h1>*/}

            </div>





            <div className='flex justify-center p-4'>
                <p className='text-white text-2xl chalk text-center'> A simple, powerful, motivating, productivity visualizer & habit tracker </p>

            </div>

            <div className='pt-10'>

                 <div className='flex justify-center'>
                    <div className='grid grid-cols-1 md:flex justify-center gap-20 pb-20'>


                        <img src={homeView} className='rounded-2xl shadow-xl shadow-black' alt="" height={300} width={300}/>
                        <img src={mmaTraining} className='rounded-2xl shadow-xl shadow-black' alt="" height={300} width={300}/>
                        <img src={sixWidgets} className='rounded-xl shadow-xl shadow-black' alt="" height={300} width={300}/>
                        {/*<img src={sixWidgets} className='rounded-xl' alt="" height={300} width={300}/>*/}

                    </div>

                 </div>


                <div className='flex-col md:flex-row flex justify-center pb-20'>
                    <h1 className='text-white text-6xl text-center p-10 chalk'>
                        Elegant.
                    </h1>

                    <h1 className='text-white text-6xl text-center p-10'>
                         Simple.
                    </h1>

                    <h1 className='text-white text-6xl text-center p-10 chalk'>
                        Moving.
                    </h1>

                </div>
                <div className="flex justify-center">
                    <div className='grid grid-cols-1 md:flex justify-center gap-20 pb-10'>
                        <img src={purple1} className='rounded-xl shadow-xl shadow-black' alt="" height={300} width={300}/>
                        <img src={graphKey} className='rounded-xl shadow-xl shadow-black' alt="" height={300} width={300}/>
                        <img src={addActivity} className='rounded-2xl shadow-xl shadow-black' alt="" height={300} width={300}/>
                    </div>

                </div>

                <div className='flex justify-center'>
                    <p className='text-2xl text-white sm:p-20 p-10 text-center'>
                        With a simple but elegant design, you are motivated to earn your tile and keep your graph full! Enabling you to quickly see when you might be taking unwanted time off. Or sacrificing one habit for another.
                    </p>
                </div>

                <div>
                    <p className='flex justify-center pb-10'>
                        <a href="https://testflight.apple.com/join/hNbNRHO9">Beta Link</a>
                    </p>
                </div>
            </div>

        </div>


    )

}