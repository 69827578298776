import {ProjectCard} from "../components/ProjectCard";
import {useState} from "react";
import axios from "axios";
import {useEffect} from "react";
import '../styles/my-studio.css'
import realLogo from "../assets/BetaLogoNoBG.png"
import activityTilesIcon from "../assets/TileGraphIconRainbow.png"
import dayzTillLogo from "../assets/dayzTillLogo.jpg"
import meAndFits from "../assets/FitzAndMe.png"
import meAndTrixie from "../assets/trixie.png"
import bacPro from "../assets/baculatorProLogo.png"
import kpLogo from "../assets/KPLogo.png"
import spaceLogo from "../assets/spaceLogo.png"
import arptLogo from "../assets/ARPTLOGO.png"
import teLogo from "../assets/tape-eater.png"
import prohibition from "../assets/prohibiton_appstore.png"

export const MyStudio = () => {
    const [repos, setRepos] = useState([])
    const [showDesui, setShowDesui] = useState(false)
    const fetchRepoData = () => {
        axios.get('https://api.github.com/users/corith/repos').then(
            (response => setRepos(response.data))
        )
    }

    const getRandomNum = () => {
        return Math.floor(Math.random() * (4 - 1) + 1)
    }

    useEffect(() => {
        fetchRepoData()
    }, [])

    return (
        // <div className='collision-container'>

        <div className={'' + (showDesui ? 'bg-gradient-to-bl from-orange-700 via-pink to-purple-800 shadow-inner shadow-black' : 'bg-black pb-10  rounded-b-xl')}>


            {/*<div className='flex flex-col'>*/}



            {/*<h1 className='text-center text-white font-bold sm:text-6xl text-5xl  pt-16 pb-4'>  {showDesui ? "" : "Swift Creations 🍎"} </h1>*/}
            <div className='flex justify-center p-20'>
                <button
                    className='shadow-inner shadow-gray-500 text-center text-white text-xl font-bold items-center bg-gradient-to-tr from-gray-700 via-black to-gray-800 rounded-xl p-2 '
                    onClick={event => {
                        setShowDesui(!showDesui)
                    }}>
                    {!showDesui ? "Other Projects 🗂️" : "My Swift Creations 🍎"}
                </button>
            </div>

            {
                !showDesui ?
                    <div className='flex justify-center'>
                        <div className='sm:w-3/4'>
                            <h1 className='text-center text-white font-bold sm:text-6xl text-5xl pb-20'> Swift Creations 🍎 </h1>


                            <div className="p-5 sm:p-0">

                                <div className='pt-5 mb-20 sm:pl-0 sm:pr-0 sm:pb-0'>
                                    <div
                                        className='shadow-inner shadow-orange-600 flex justify-center  bg-gradient-to-tr from-gray-800 via-black to-gray-500 rounded-xl p-3'>
                                        <div>
                                            <div className='flex justify-center flex-col'>
                                                <h1 className='sm:text-5xl text-4xl text-white pt-5 pb-7 flex justify-center'>Tape
                                                    Eater!</h1>
                                                <div className='p-2 shadow-inner shadow-black rounded-xl'>
                                                    <h4 className='flex justify-center text-2xl te-orange'>Mixtape
                                                        Player</h4>

                                                </div>

                                            </div>

                                            <div className='flex flex-col  pt-10'>

                                                <div className='flex justify-center'>
                                                    <img src={teLogo} alt="Baculator Pro Logo" width='200' height='390'
                                                         className='shadow-orange-600 shadow-md rounded-full'/>

                                                </div>


                                                <div className='flex justify-center'>

                                                    <a href="https://apple.co/4b30VbW" className='p-5'>
                                                        <img
                                                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                                                            alt="Download on the App Store"/>

                                                    </a>


                                                </div>

                                                <div className='flex justify-center'>
                                                    <div className=''>
                                                        <a href="/tape-eater"
                                                           className='te-orange text-sm font-bold rounded-full '>Learn
                                                            More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div
                                    className="shadow-inner shadow-gray-400 bg-gradient-to-br from-gray-600 via-black to-gray-600 rounded-xl p-3">
                                    <div className='shadow-inner shadow-black rounded-xl'>

                                        <div className="flex justify-center pt-5">
                                            <h1 className="text-gray-500 sm:text-5xl text-4xl text-center"> Fresh out of
                                                Xcode! </h1>
                                        </div>

                                        <div className="flex justify-center py-5">
                                            <p className="font-bold text-green-400 text-xl  bg-gray-700 rounded-xl p-2 chalk shadow-inner shadow-black">Activity
                                                Tiles</p>
                                        </div>

                                        <div className="flex justify-center ">
                                            <img src={activityTilesIcon} alt=""
                                                 className="rounded-3xl shadow-2xl shadow-gray-500 shadow" width="200"/>
                                        </div>

                                        <div className="flex justify-center pt-5">
                                            <a href="https://apps.apple.com/us/app/activity-tiles/id1670003422?platform=iphone"
                                               className='p-5 shadow-inner shadow-black rounded '>
                                                <img
                                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                                                    alt="Download on the App Store"/>

                                            </a>

                                        </div>

                                        <div className='flex justify-center py-5 '>
                                            <div className=''>
                                                <a href="/dev"
                                                   className='text-white font-bold p-2 rounded-full bg-gray-700 shadow shadow-black'>Learn
                                                    More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-20 pb-5 pl-5 pr-5 sm:pl-0 sm:pr-0 sm:pb-0">
                                <div
                                    className='shadow-inner shadow-purple-500 text-center text-white text-xl font-bold rounded-2xl p-4 bg-gradient-to-r from-purple-700 via-black to-purple-700 flex flex-col md:flex-row sm:justify-around justify-center'>


                                    <div>
                                        <h1 className='text-4xl'>Darts Keeper!</h1>
                                        <h4> 1,000+ Downloads on the App Store 😈</h4>
                                        <div className='flex flex-col items-center pt-10'>

                                            <img src={realLogo} alt="beta logo of a unicorn" width='200' height='390'
                                                 className='rounded-full shadow-inner shadow-black p-2'/>

                                            <a href="https://apps.apple.com/us/app/darts-keeper/id1570511877?itsct=apps_box_badge&amp;itscg=30200"
                                               className='p-5'>
                                                <img
                                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                                                    alt="Download on the App Store"/>

                                            </a>

                                            <div className='flex justify-center'>
                                                <div className=''>
                                                    <a href="/darts"
                                                       className='text-white text-sm font-bold rounded-full '>Learn
                                                        More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className=''>
                                        <h1 className='text-4xl'>Dayz Till!</h1>
                                        <h4> THE simplest countdown app‼️</h4>
                                        <div className='flex flex-col items-center pt-10'>

                                            <img src={dayzTillLogo} alt="Dayz Till Logo" width='200' height='390'
                                                 className='rounded-full shadow-inner shadow-black p-4'/>

                                            <a href="https://apple.co/42JACTO" className='p-5'>
                                                <img
                                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1640563200&h=125017971f11207abca7897bc5140364"
                                                    alt="Download on the App Store"/>

                                            </a>

                                            <div className='flex justify-center'>
                                                <div className=''>
                                                    <a href="/dayztill"
                                                       className='text-white text-sm font-bold rounded-full '>Learn
                                                        More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>


                            <div className='pt-20  pb-5 pl-5 pr-5 sm:pl-0 sm:pr-0 sm:pb-0'>
                                <div
                                    className='shadow-inner shadow-yellow-600 flex justify-center  bg-gradient-to-tr from-gray-800 via-black to-yellow-800 rounded-xl p-3'>
                                    <div>
                                        <div className='flex justify-center flex-col'>
                                            <h1 className='sm:text-5xl text-4xl text-white pt-5 pb-7 flex justify-center'>Baculator
                                                Pro!</h1>
                                            <div className='p-2 shadow-inner shadow-black rounded-xl'>
                                                <h4 className='flex justify-center text-white text-2xl'> We've got your
                                                    BAC‼️</h4>

                                            </div>

                                        </div>
                                        <div className='flex flex-col  pt-10'>

                                            <div className='flex justify-center'>
                                                <img src={bacPro} alt="Baculator Pro Logo" width='200' height='390'
                                                     className='rounded-2xl shadow-amber-400 shadow shadow-2xl'/>

                                            </div>


                                            <div className='flex justify-center'>

                                                <a href="/baculator" className='p-5'>
                                                    <img
                                                        src={prohibition}
                                                        alt="Download on the App Store"/>

                                                </a>


                                            </div>

                                            <div className='flex justify-center'>
                                                <div className=''>
                                                    <a href="/baculator"
                                                       className='text-white text-sm font-bold rounded-full '>Learn
                                                        More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='pt-20  pb-5 pl-5 pr-5 sm:pl-0 sm:pr-0 sm:pb-0'>
                                <div
                                    className='shadow-inner shadow-red-500 flex justify-center flex-col bg-gradient-to-br from-gray-700 via-black to-red-600 rounded-xl p-3'>
                                    <div className='flex justify-center flex-col'>
                                        <h1 className='text-6xl text-white pt-5 flex justify-center'>ARPT</h1>
                                        <h4 className='flex justify-center text-white text-xl pt4'>Apex Ranked Pocket
                                            Tracker</h4>
                                        <h4 className='flex justify-center text-white opacity-50 text-lg '>Recently
                                            killed ⚰️</h4>
                                    </div>

                                    <div className="flex justify-center py-10">
                                        <img src={arptLogo} alt=""
                                             className="rounded-3xl shadow-2xl shadow-red-500 shadow-" width="200"/>
                                    </div>

                                    <div className="flex justify-center p-2">
                                        <div className='shadow-inner shadow-black rounded-xl'>
                                            <p className="font-bold text-lg p-3 rounded-xl "><a
                                                href="https://testflight.apple.com/join/mhzx2R2S"
                                                className="text-red-500">Beta Link 🔗</a></p>

                                        </div>
                                    </div>

                                    <div className='flex justify-center p-2 pt-5'>
                                        <div className=''>
                                            <a href="/arpt" className='text-white font-bold '>Learn More</a>

                                        </div>

                                    </div>
                                </div>

                            </div>


                            <div className="pt-20 pb-5 pl-5 pr-5 sm:pl-0 sm:pr-0 sm:pb-0">
                                <div
                                    className="shadow-inner shadow-gray-500 bg-gradient-to-r from-black via-gray-900 to-black rounded-xl p-3 flex flex-col items-center">

                                    <div className='flex justify-center text-purple-300 text-3xl font-bold py-5'>
                                        iOS Graveyard 🪦⚰️☠️
                                    </div>

                                    <div className="flex flex-col pb-5">


                                        <div className='pt-4 text-purple-200 text-lg'>
                                            <div className='flex justify-left'>


                                                <img src={spaceLogo} alt="" width={75} height={75}
                                                     className="rounded-xl"/>
                                                <span className="pl-5">
                                                 A "Galaga" like game I built to explore UIKit and SpriteKit on iOS.
                                                    {/*<a href="https://testflight.apple.com/join/NI75shaM">&nbsp;TestFlight link</a>*/}
                                            </span>
                                            </div>
                                        </div>

                                        <div className='pt-4 text-purple-200 text-lg'>
                                            <div className='flex justify-left'>
                                                <img src={kpLogo} alt="" height={75} width={75} className="rounded-xl"/>
                                                <span className="pl-5">
                                                    An app that allows users to track their training at a Martial Arts gym.
                                                    {/*<a href="https://testflight.apple.com/join/qBcRNoKS">&nbsp;TestFlight link</a>*/}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>


                        </div>

                        <div className='h-screen'>

                        </div>
                    </div>

                    :
                    <div className=''>
                        {/*<div className='sm:heightClass'>*/}


                        <div className='flex justify-between p-5'>

                            <img src={meAndFits} alt="" width={200} className='rounded-full hidden md:block ml-20'/>

                            <div className='flex flex-col items-end'>
                                <h1 className='text-white text-7xl font-bold'>My Other Projects</h1>
                                <h1 className='text-center text-white text-xl p-2 mb-4'> Check me out on <a
                                    href="https://github.com/corith" target="_blank" rel="noreferrer"><span
                                    className='text-orange-400'>GitHub 🤓</span></a></h1>
                            </div>


                            <img src={meAndTrixie} alt="" width={200} className='hidden md:block mr-20'/>

                        </div>

                        <div className='flex justify-center'>
                            <img src={meAndTrixie} alt="" width={200} className={showDesui ? "sm:hidden " : ""}/>
                        </div>

                        <div className=''>
                            <div
                                className='grid lg:grid-rows-2 lg:grid-cols-2ms 2xl:grid-cols-3 gap-9 justify-center p-4'>
                                {
                                    repos.map((repo, i) => {
                                        return (
                                            <ProjectCard
                                                key={i}
                                                projName={repo.name}
                                                projDes={repo.description}
                                                repoUrl={repo.html_url}
                                                lang={repo.language}
                                                bgleft={getRandomNum()}
                                                i={i}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

            }

            {/*</div>*/}
        </div>

    )

}

