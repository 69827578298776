
import {useState} from "react";
import ss0 from "../../assets/baculator/420_lockscreen.png";
import ss1 from "../../assets/baculator/about_baculator_pro.png";
import ss2 from "../../assets/baculator/already_started_3_beers.png";
import ss3 from "../../assets/baculator/HomeScreen_pointsevenseven.png";
import ss4 from "../../assets/baculator/session_log_drinks_expanded.png";
import ss5 from "../../assets/baculator/settings_menu.png";
import ss6 from "../../assets/baculator/widets_live_activity.png";
import ss7 from "../../assets/baculator/SessionLogViewActiveSession.png";
import logo from "../../assets/baculatorProLogo.png"
import prohibition from "../../assets/prohibiton_appstore.png";
export function BaculatorInfo() {
    const [imgs, setImgs] = useState([ss3, ss2, ss7, ss4, ss1, ss5, ss6, ss0]);
    const [index, setIdx] = useState(0);
    const [img, setImg] = useState(ss3);


    return(
        <div className='bg-black pb-20 '>

            <div className='flex justify-center pt-10'>
                <h1 className='text-5xl lg:text-6xl font-bold text-white'> Baculator Pro </h1>
            </div>
            <div className='flex justify-center pb-10'>
                <h5 className='text-lg font-bold text-yellow-500'>For the Pros!</h5>
            </div>

            <div className='flex justify-center pb-10'>
                <img src={logo} height={500} width={500} alt="" className='rounded-2xl' style={{}}/>
            </div>


            <div className='flex justify-center'>
                <p className='text-white pb-4'>Tap / click Image to cycle</p>
            </div>


            <div className='flex justify-center'>


                <img src={img} alt="" width={300} className='rounded-3xl shadow shadow-gray-500' onClick={(e) => {
                    console
                        .log("index = " + index)
                    setImg(imgs[index])
                    index === imgs.length - 1 ? setIdx(0) : setIdx(index + 1)
                }}/>


            </div>


            <div className='flex justify-center'>
                <div className='text-yellow-500 md:w-1/3 text-center p-10 text-lg'>
                    <p className='pb-10'>
                        Unfortunately, App Store review has removed Baculator Pro from the App Store saying the app was
                        in
                        violation of the
                        App Review Guidelines, specifically "1.4 Physical Harm".
                    </p>
                    <p className='pb-10'>
                        This occurred after being on the App Store for over 2 years and having a steady influx of paying
                        users. This app consistently ranked
                        in the top 30 for "Food & Drink". The App analytics continue to show active user's every week.
                        But
                        no more downloads are possible.
                    </p>
                    <p>
                        I sincerely appreciate those that bought the app and I apologize for the inconvenience and end
                        of
                        life.

                    </p>
                    <p className='pt-5'>
                        Cheers.
                    </p>

                </div>
            </div>


            <div className='flex justify-center'>

                <a href="/baculator" className='p-5'>
                    <img
                        src={prohibition}
                        alt="Download on the App Store"/>

                </a>


            </div>


        </div>
    )
}